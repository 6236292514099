import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import { SearchContext } from '../context/SearchContext'

const ListItem = ({ servicio, tramo }) => {

    const { comprar, fancyDate } = useContext(SearchContext);

    const getHoursDiff = (a, b) => {
        return Math.abs((a - b) / 1000 / 60 / 60);
    }

    return (
        <div className="list-item box_general wow fadeIn ctn-pasaje-list-item ">
            <div className="row">
                <div className="col-lg-2 col-md-12 item-company-name d-flex flex-column ">
                    <small className='d-lg-none'><b> Empresa</b></small>
                    <h6>{servicio.transportista.nombre}</h6>
                    {servicio.aceptaETicket && <p className='border border-1 rounded p-2 d-flex align-items-center justify-content-center text-align-center mt-2 '>
                        E-ticket
                    </p>}
                </div>
                <div className="col-lg col-6 item-data d-flex flex-column">
                    <small className='d-lg-none'><b> Salida </b></small>
                    {/* <p>{fancyDate(servicio.fechaHoraSalida)}</p> */}
                    <p > {fancyDate(servicio.fechaHoraSalida).fecha}</p>
                    <p>{fancyDate(servicio.fechaHoraSalida).hora}</p>
                </div>
                <div className="col-lg col-6 item-data d-flex flex-column">
                    <small className='d-lg-none'><b> Llegada </b></small>
                    {/* <p>{fancyDate(servicio.fechaHoraLlegada)}</p> */}
                    <p>{fancyDate(servicio.fechaHoraLlegada).fecha}</p>
                    <p>{fancyDate(servicio.fechaHoraLlegada).hora}</p>
                </div>
                <div className="col-lg col-6 item-data">
                    <p>{servicio.clase}</p>
                </div>
                <div className="col-lg col-6 item-data">
                    <small>
                        Duracion: {Math.floor(getHoursDiff(new Date(servicio.fechaHoraLlegada), new Date(servicio.fechaHoraSalida)))} hs.<br />
                        Butacas: {servicio.butacas}<br />
                    </small>
                </div>
                <div className="col-lg-2 col-md-12 item-price">
                    <div>
                        <h6><small>$</small>{(servicio.precio + servicio.serviceCharge).toLocaleString('es-ES', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                        </h6>
                        <small>Por Persona</small><br />
                        <button onClick={() => comprar(servicio, tramo)} className="btn_1 small">Comprar </button>
                    </div>
                </div>
            </div>
            <div className="row text-center">
                <small><b>Tarifa incluye impuestos y cargo por servicio. </b></small>
            </div>
            <hr className="mb-3" />
        </div>
    )
}

export default ListItem