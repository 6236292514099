import React, { useContext, useEffect, useState } from 'react'

import SeatReferences from '../components/SeatReferences';

import PassengerModal from '../components/PassengerModal';
import { SearchContext } from '../context/SearchContext';
import SeatMap from "./SeatMap";
import AlertMessage from './AlertMessage';
import { useNavigate } from 'react-router-dom';

const Taquilla = ({ handleOpenAlertModal, handleCloseAlertModal, showAlertModal, showButtons, tramoSeleccionado, direccion, vueltaSeleccionada, idaSeleccionada, volverPaso2, comprarPasajes }) => {

    const [showModal, setShowModal] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const
        {
            step, setStep,
            servicios, setServicios,
            origen, setOrigen,
            destino, setDestino,
            origenId, setOrigenId,
            destinoId, setDestinoId,
            ida, setIda,
            vuelta, setVuelta,
            pax, setPax,
            loading, setLoading,
            fancyDate,
            listaPasajeros, setListaPasajeros,
        } = useContext(SearchContext);

    const [taquilla, setTaquilla] = useState();
    const [butacas, setButacas] = useState([]);
    const [ultButacaSeleccionada, setUltButacaSeleccionada] = useState();




    const getTaquilla = async () => {
        setLoading(true);
        try {
            let currentToken = sessionStorage.getItem('currentToken');

            const response = await fetch(process.env.REACT_APP_API + `/buses/servicios/${tramoSeleccionado.codigoServicio}/taquilla`, {
                headers: {
                    "Content-type": "application/json;charset=UTF-8",
                    "token": currentToken
                },
                method: 'GET'
            });
            const data = await response.json();

            setTaquilla(data);
            parseButacas(data.mapaButacas.secciones);

        } catch (error) {
            handleOpenAlertModal('Hubo un error intente nuevamente', 'error en getTaquilla')

        } finally {
            setLoading(false)
        }
    }

    const parseButacas = (secciones) => {
        let ret = [];
        let sec1 = secciones[0].items ?? [];
        let sec2 = secciones[1]?.items ?? [];



        if (sec2.length > 0) {
            sec1.forEach(item => {
                ret[item.numero] = item.ocupado;
            });
        }
        if (sec2.length > 0) {
            sec2.forEach(item => {
                ret[item.numero] = item.ocupado;
            });
        }
        setButacas(ret); // [1: false, 2:false ...]

    }

    const getAvailableClass = (index) => {
        let ret = butacas[index] ? "bus-asiento-ocupado" : "bus-asiento-disponible";
        return ret;
    }

    const tooglePassangerModal = (index) => {
        return butacas[index] ? {} : { "data-bs-toggle": "modal", "data-bs-target": "#passengerModal" + direccion };
    }

    const modalClick = (index) => {

        setUltButacaSeleccionada(index);
    }

    //Funcion para que al seleccionar butaca quede marcada como ocupada
    function ocuparButaca(butacaSeleccionada) {

        for (const seccion of taquilla.mapaButacas.secciones) {
            for (const item of seccion.items) {
                if (item.numero == butacaSeleccionada) {
                    item.ocupado = true;
                    item.seleccionado = true;
                    return;
                }
            }
        }
    }
    const handleOpenModal = () => {
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setErrorMessage("");
        setShowModal(false);
    };

    useEffect(() => {
        getTaquilla();
    }, []);

    return (
        <>
            {
                showAlertModal.visible && <AlertMessage handleCloseAlertModal={handleCloseAlertModal} typeMessage={showAlertModal.typeMessage} showAlertModal={showAlertModal} text={showAlertModal.message} />
            }
            <div id="results">
                <div className="container-fluid" id={showButtons ? 'pasajeVuelta' : ''}>
                    <div className="row">
                        <div className="col-md-12 text-center ">
                            {
                                direccion === "vuelta" ?
                                    <h4 >Tramo de Vuelta</h4>
                                    :
                                    <h4 >Tramo de Ida</h4>
                            }
                        </div>
                    </div>
                </div>
            </div>

            <div className=" margin_60  px-xl-5 ">

                <div className="row ">
                    <aside className=" col-lg-6 bus-container" >
                        <div className="box_general_3 booking">
                            <form>
                                <div className="row">
                                    {taquilla && (
                                        <SeatMap handleOpenModal={handleOpenModal} mapaButacas={taquilla.mapaButacas} modalClick={modalClick} direccion={direccion} />
                                    )}

                                </div>
                            </form>
                            <div className='container-seatReferences'>
                                <SeatReferences />
                            </div>
                        </div>
                    </aside>


                    {/* CONTENEDOR DETALLES PASAJE */}

                    <div className="col-lg-6 passenger-container">
                        <div className={`box_general_3 ctn-info-pasajes cart d-flex flex-column ${showButtons && direccion === "vuelta" ? 'justify-content-between' : 'justify-content-start'} `}>
                            <div className="form_title mb-4">
                                <h3><strong className="container-icon-trim"><i className="icon-right-2"></i></strong>
                                    {direccion === "ida" && <>{origen} A {destino} </>}
                                    {direccion === "vuelta" && <>{destino} A {origen} </>}
                                </h3>
                                <p>Tramo de {direccion}</p>
                            </div>

                            <div>
                                {/* <!--/Column Titles --> */}
                                <div className="list-item box_general wow fadeIn box-info-pasaje">
                                    <div className="row text-center d-none d-lg-flex ctn-titles-info-pasaje ">
                                        <div className="col-lg-2 ">
                                            <small>Empresa</small>
                                        </div>
                                        <div className="col-lg">
                                            <small>Salida</small>
                                        </div>
                                        <div className="col-lg">
                                            <small>Llegada</small>
                                        </div>
                                        <div className="col-lg">
                                            <small>Comodidad</small>
                                        </div>
                                        <div className="col-lg">
                                            <small>Informacion</small>
                                        </div>
                                        <div className="col-lg-2">
                                            <small>Precio</small>
                                        </div>
                                    </div>
                                    <hr className="mt-0 mb-3" />
                                    <div className="row ctn-info-titles">
                                        <div className="col-lg-2 col-md-12 item-company-name d-flex flex-column">
                                            <small className='d-lg-none'><b> Empresa</b></small>
                                            <h6>{tramoSeleccionado.transportista.nombre}</h6>
                                        </div>
                                        <div className="col-lg col-6 item-data d-flex flex-column">
                                            <small className='d-lg-none'><b> Salida </b></small>
                                            {/* <p>{fancyDate(tramoSeleccionado.fechaHoraSalida)}</p> */}
                                            <p > {fancyDate(tramoSeleccionado.fechaHoraSalida).fecha}</p>
                                            <p>{fancyDate(tramoSeleccionado.fechaHoraSalida).hora}</p>
                                        </div>
                                        <div className="col-lg col-6 item-data d-flex flex-column">
                                            <small className='d-lg-none'><b> Llegada </b></small>
                                            {/* <p>{fancyDate(tramoSeleccionado.fechaHoraLlegada)}</p> */}
                                            <p>{fancyDate(tramoSeleccionado.fechaHoraLlegada).fecha}</p>
                                            <p>{fancyDate(tramoSeleccionado.fechaHoraLlegada).hora}</p>
                                        </div>
                                        <div className="col-lg col-6 item-data">
                                            <p>{tramoSeleccionado.clase}</p>
                                        </div>
                                        <div className="col-lg col-6 item-data">
                                            <div>
                                                Butacas: {tramoSeleccionado.butacas}<br />
                                            </div>
                                        </div>
                                        <div className="col-lg-2 col-md-12 item-price">
                                            <div>
                                                <h6><small>$</small>{(tramoSeleccionado.precio + tramoSeleccionado.serviceCharge).toLocaleString('es-ES', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</h6>
                                                <small>Por Persona</small><br />
                                            </div>
                                        </div>
                                    </div>
                                </div>



                                {/* <!-- Passengers List --> */}
                                {listaPasajeros[direccion].length > 0 &&
                                    <div className="passengers-list" >
                                        <h5 className="mt-5">Lista de pasajeros</h5>

                                        <div className="row text-center passenger-list-titles d-none d-lg-flex">
                                            <div className="col-lg-1 ">
                                                <small>Butaca</small>
                                            </div>
                                            <div className="col-lg-3">
                                                <small>DNI</small>
                                            </div>
                                            <div className="col-lg-4">
                                                <small>Nombre</small>
                                            </div>
                                            <div className="col-lg-4">
                                                <small>Apellido</small>
                                            </div>
                                        </div>

                                        {listaPasajeros[direccion].map((p) => {
                                            return (
                                                <div className="row" key={p.pasajero.numeroDocumento} >
                                                    <div className="col-lg-1 item-data d-flex flex-column">
                                                        <small className='d-lg-none'><b>Butaca</b></small>
                                                        <p>{p.butaca}</p>
                                                    </div>
                                                    <div className="col-lg-3  item-data  d-flex flex-column">
                                                        <small className='d-lg-none'><b>DNI</b></small>

                                                        <p>{p.pasajero.numeroDocumento}</p>
                                                    </div>
                                                    <div className="col-lg-4 item-data  d-flex flex-column">
                                                        <small className='d-lg-none'><b>Nombre</b></small>

                                                        <p>{p.pasajero.nombre}</p>
                                                    </div>
                                                    <div className="col-lg-4  item-price  d-flex flex-column">
                                                        <small className='d-lg-none'><b>Apellido</b></small>

                                                        <p>{p.pasajero.apellido}</p>

                                                    </div>
                                                </div>
                                            )
                                        })
                                        }
                                    </div>
                                }

                                <hr />

                                <div className="alert alert-secondary text-center" role="alert">
                                    <p className="mb-0">
                                        <strong>¡IMPORTANTE!</strong><br />
                                        Si viaja con menores conozca la reglamentación vigente <a target='_blank' href="AutorizacionMenores.pdf" >aquí</a>
                                    </p>
                                </div>
                                <div className="alert alert-secondary text-center" role="alert">
                                    Es obligatorio viajar con el documento declarado en este pasaje según <a href="https://www.boletinoficial.gob.ar/detalleAviso/primera/153519/20161108" >Res 76-E-2016</a>
                                </div>
                            </div>

                            <div className=" mt-4 ">
                                {/* <h5 className="text-center">Subtotal: <small>$</small>{pax * (((idaSeleccionada?.precio + idaSeleccionada?.serviceCharge) ?? 0) + ((vueltaSeleccionada?.precio ?? 0 + (vueltaSeleccionada?.serviceCharge ?? 0)))).toFixed(2)}</h5> */}
                       
                                {/* <p>
                              {idaSeleccionada?.precio} +
                              {idaSeleccionada?.serviceCharge} 
                                ++ 
                                {vueltaSeleccionada?.precio} +
                                {vueltaSeleccionada?.serviceCharge}
                                </p> */}
                       
                                {
                                    showButtons &&
                                    <h5 className="text-center">
                                    Subtotal: <small>$</small>
                                    {(pax * (
                                        ((idaSeleccionada?.precio ?? 0) + (idaSeleccionada?.serviceCharge ?? 0)) +
                                        ((vueltaSeleccionada?.precio ?? 0) + (vueltaSeleccionada?.serviceCharge ?? 0))
                                    )).toLocaleString('es-ES', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                </h5>
                                }
                                

                                <hr />
                                <div className="row">
                                    <div className="col-lg-6 text-lg-start text-center mb-lg-0 mb-3">
                                        <button onClick={volverPaso2} className="btn_1 outline medium btn-volver-taquilla"><i className="icon-left-open-2"></i> Volver a Seleccionar</button>
                                    </div>
                                    <div className="col-lg-6 text-lg-end text-center">
                                        {/*CONTENEDOR BOTONES */}
                                        {
                                            showButtons ?
                                                <button onClick={async () => await comprarPasajes()} className="btn_1 medium btn-confirmar-taquilla">Confirmar y comprar<i className="icon-right-open-2"></i></button>
                                                :
                                                <a href='#pasajeVuelta'   className={`btn_1 medium text-decoration-none btn-continuar 
                                                    ${listaPasajeros['ida'].length == pax 
                                                    ? '' 
                                                    : 'opacity-50 text-decoration-none pe-none'}`} >Continuar Selección<i className="icon-right-open-2"></i></a>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* FIN CONTENEDOR DETALLES PASAJE */}

                </div>


                <div className="alert alert-warning mt-4" role="alert" style={{ display: "none" }}>
                    <div className="row">
                        <div className="col text-center d-flex align-items-center justify-content-center">
                            <i className="icon-warning-empty"></i>
                        </div>
                        <div className="col-11">
                            "Para saber los detalles de PreViaje ingresa al siguiente link" https://previaje.gob.ar/como-funciona .
                            Los requisitos sanitarios de ingreso a cada territorio debido al Covid-19 están determinados por las autoridades competentes, que pueden variar en el tiempo. Es responsabilidad del pasajero corroborar los requerimientos con la autoridad de cada jurisdicción antes del viaje. La ausencia de los requisitos es responsabilidad exclusiva del pasajero.
                        </div>
                    </div>
                </div>

                <hr className="separator-passengers" />


            </div>
            <PassengerModal
                errorMessage={errorMessage}
                showModal={showModal}
                handleOpenModal={handleOpenModal}
                handleCloseModal={handleCloseModal}
                ocuparButaca={ocuparButaca}
                listaPasajeros={listaPasajeros}
                setListaPasajeros={setListaPasajeros}
                direccion={direccion}
                ultButacaSeleccionada={ultButacaSeleccionada}
                importeFinal={tramoSeleccionado.precio + tramoSeleccionado.serviceCharge}
                butacas={butacas}
                setButacas={setButacas}
                pax={pax} />

        </>



    )
}

export default Taquilla