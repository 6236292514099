import React from 'react'
import { useState, useEffect } from 'react';
import CountryOptions from './CountryOptions';
import bootstrap from 'bootstrap/dist/js/bootstrap.bundle';
import $ from 'jquery';
import '../styles/PassengerModal.css'
const PassengerModal = ({ errorMessage, showModal, handleCloseModal, handleOpenModal, ocuparButaca, listaPasajeros, setListaPasajeros, direccion, ultButacaSeleccionada, importeFinal, butacas, setButacas, pax }) => {


    const [maxDate, setMaxDate] = useState('');

    const [formValues, setFormValues] = useState({
        Butaca: ultButacaSeleccionada,
        Nombre: '',
        Apellido: '',
        NacionalidadId: '1',
        TipoDocumentoId: '1',
        DocumentoNumero: '',
        FechaNacimiento: '',
        Sexo: 'M',
        CodigoDeArea: '',
        TelefonoSinCodigo: '',
        Email: ''
    });

    useEffect(() => {
        setFormValues((prevFormValues) => ({
            ...prevFormValues,
            Butaca: ultButacaSeleccionada
        }));
    }, [ultButacaSeleccionada]);


    useEffect(() => {
        const today = new Date();
        const year = today.getFullYear();
        const month = String(today.getMonth() + 1).padStart(2, '0');
        const day = String(today.getDate()).padStart(2, '0');
        const formattedDate = `${year}-${month}-${day}`;
        setMaxDate(formattedDate);
    }, []);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormValues({ ...formValues, [name]: value });
    };



    const formatPasaje = (formVal) => {
        return {
            "butaca": formVal.Butaca,
            "importe": importeFinal,
            "pasajero": {
                "nombre": formVal.Nombre,
                "apellido": formVal.Apellido,
                "fechaNacimiento": formVal.FechaNacimiento,
                "tipoDocumento": formVal.TipoDocumentoId,
                "numeroDocumento": formVal.DocumentoNumero,
                "nacionalidad": formVal.NacionalidadId,
                "residencia": 1,
                "razonSocial": "11222333",
                "tipoDocumentoFiscal": 1,
                "numeroDocumentoFiscal": "123456798",
                "idCondicionImpositiva": 1,
                "ocupacion": "empleado",
                "estadoCivil": "soltero",
                "sexo": formVal.Sexo,
                "email": formVal.Email,
                "codigoArea": formVal.CodigoDeArea,
                "telefono": formVal.TelefonoSinCodigo
            }
        }
    }

    const validPax = (pax) => {
        for (let key in pax) {

            if (pax[key] === '') {
                return false;
            }
        }

        return true;
    }

    const handlePaxSubmit = (e) => {
        e.preventDefault();



        if (validPax(formValues)) {

            let formatedPax = formatPasaje(formValues);

            const updatedListaPasajeros = { ...listaPasajeros };
            updatedListaPasajeros[direccion].push(formatedPax);
            setListaPasajeros(updatedListaPasajeros);

            const updatedButacas = { ...butacas };
            updatedButacas[formatedPax.butaca] = true;
            setButacas(updatedButacas);


            setFormValues({
                Butaca: '',
                Nombre: '',
                Apellido: '',
                NacionalidadId: '1',
                TipoDocumentoId: '1',
                DocumentoNumero: '',
                FechaNacimiento: '',
                Sexo: 'M',
                CodigoDeArea: '',
                TelefonoSinCodigo: '',
                Email: ''
            });
            ocuparButaca(ultButacaSeleccionada)
            handleCloseModal()
        } else {
            alert('Debe completar todos los campos');
        }
    };


    return (
        <>
            {showModal &&
                <>
                    <div className="custom-modal-backdrop" onClick={handleCloseModal}></div>
                    <div className="custom-modal " id={`passengerModal${direccion}`}>
                        <div className="modal-dialog modal-dialog-passenger-modal p-4">
                            <div className="modal-content">
                                <form id="form-datos-pasajero" onSubmit={handlePaxSubmit} method='post' >
                                    <div className="modal-header modal-header-passenger-modal">
                                        <h5 className="modal-title w-100 text-center" id="passengerModalLabel">
                                            Carga de pasajeros - {direccion}
                                        </h5>
                                        <button type="button" className="btn-close" onClick={handleCloseModal} ></button>
                                    </div>
                                    {listaPasajeros[direccion] && pax > listaPasajeros[direccion].length &&
                                        <>
                                            <div className="modal-body">
                                                {errorMessage && (
                                                    <div className="alert alert-danger text-center" role="alert">
                                                        {errorMessage}
                                                    </div>
                                                )}

                                                <div className="row mb-2">
                                                    <div className="col-xs-12 col-sm-6">
                                                        <div className="form-group form-group-passenger-modal">
                                                            <label htmlFor="Nombre">Nombre(s)</label>
                                                            <input required className="form-control form-control-passenger-modal"
                                                                id="Nombre" maxLength="27" name="Nombre" tabIndex={3} type="text"
                                                                onChange={handleInputChange}
                                                                value={formValues.Nombre} />
                                                        </div>
                                                    </div>
                                                    <div className="col-xs-12 col-sm-6">
                                                        <div className="form-group form-group-passenger-modal">
                                                            <label htmlFor="Apellido">Apellido(s)</label>
                                                            <input required className="form-control form-control-passenger-modal"
                                                                id="Apellido" maxLength="27" name="Apellido" tabIndex="4" type="text"
                                                                onChange={handleInputChange}
                                                                value={formValues.Apellido} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row mb-2">
                                                    <div className="col-xs-12 col-sm-4">
                                                        <div className="form-group form-group-passenger-modal selector">
                                                            <label htmlFor="NacionalidadId">Nacionalidad</label>
                                                            <select className="form-control form-control-passenger-modal" id="NacionalidadId" name="NacionalidadId" tabIndex="5"
                                                                onChange={handleInputChange}
                                                                value={formValues.NacionalidadId}
                                                            >
                                                                <CountryOptions />
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-xs-12 col-sm-8">
                                                        <div className="row">
                                                            <div className="col-12">
                                                                <label>Tipo y Número de Documento</label>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-5">
                                                                <div className="form-group form-group-passenger-modal selector">
                                                                    <select className="form-control form-control-passenger-modal"
                                                                        id="TipoDocumentoId" name="TipoDocumentoId" tabIndex="6">
                                                                        <option value="1" data-p10-reglavalidacion="^(\d{1,2}\.\d{3}\.\d{3}|\d{1,8})$">DNI</option>
                                                                    </select>
                                                                    <input id="ActualizaDocumento" name="ActualizaDocumento" type="hidden" value="True" />
                                                                </div>
                                                            </div>
                                                            <div className="col-7">
                                                                <div className="form-group form-group-passenger-modal">
                                                                    <input className="form-control form-control-passenger-modal" id="DocumentoNumero" required
                                                                        maxLength="20" name="DocumentoNumero" tabIndex="7" type="number"
                                                                        onChange={handleInputChange}
                                                                        value={formValues.DocumentoNumero} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row mb-2">
                                                    <div className="col-xs-12 col-sm-6">
                                                        <div className="form-group form-group-passenger-modal">
                                                            <label htmlFor="FechaNacimiento">Fecha de nacimiento</label>
                                                            <input max={maxDate} className="form-control form-control-passenger-modal" id="FechaNacimiento"
                                                                name="FechaNacimiento" placeholder="dd/mm/aaaa" tabIndex="10" type="date"
                                                                onChange={handleInputChange} value={formValues.FechaNacimiento} required />
                                                        </div>
                                                    </div>
                                                    <div className="col-xs-12 col-sm-6">
                                                        <div className="form-group form-group-passenger-modal">
                                                            <span>Sexo</span><br />
                                                            <label className="mt-3"  style={{cursor: 'pointer'}}>
                                                                <input  id="SexoMasculino" name="Sexo" tabIndex="12" type="radio" value="M" onChange={handleInputChange} />
                                                                Masculino
                                                            </label>
                                                            <label className="mt-3" style={{ marginLeft: '10px', cursor: 'pointer' }}>
                                                                <input id="SexoFemenino" name="Sexo" tabIndex="13" type="radio" value="F" onChange={handleInputChange} />
                                                                Femenino
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row mb-2">
                                                    <div className="col-xs-12 col-sm-7">
                                                    
                                                        <div >

                                                            <div className="flex justify-between w-full ctn-cod-tel" style={{ display: 'flex', width: '100%' }}>
                                                                <div className="col-4 col-sm-5 ctn-cod">
                                                                    <label htmlFor="CodigoDeArea">Cód. de Área</label>
                                                                    <input className="form-control form-control-passenger-modal"
                                                                        id="CodigoDeArea" maxLength="20" minLength="1" name="CodigoDeArea" tabIndex="19" type="text"
                                                                        onChange={handleInputChange} value={formValues.CodigoDeArea} required />
                                                                </div>

                                                                <div className="col-8 col-sm-7 ctn-tel ">
                                                                    <label htmlFor="TelefonoSinCodigo">Teléfono</label>
                                                                    <input className="form-control form-control-passenger-modal"
                                                                        id="TelefonoSinCodigo" maxLength="20" minLength="4" name="TelefonoSinCodigo" tabIndex="19" type="text"
                                                                        onChange={handleInputChange} value={formValues.TelefonoSinCodigo} required />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-xs-12 col-sm-5" >
                                                        <div className="form-group form-group-passenger-modal ctn-email">
                                                            <div className="row">
                                                                <div className="col-xs-12 rigpad-no ps-sm-0">
                                                                    <label htmlFor="Email">Email</label>
                                                                    <input className="form-control form-control-passenger-modal" data-val-regex="Formato Inválido" required
                                                                        id="Email" maxLength="100" minLength="2" name="Email" tabIndex="20" type="email"
                                                                        onChange={handleInputChange} value={formValues.Email} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className="modal-footer modal-footer-passenger-modal">

                                                <button type="submit" className="btn_1 btn_1-passenger-modal m-auto">
                                                    Aceptar
                                                </button>

                                            </div>
                                        </>
                                    }
                                    {listaPasajeros[direccion] && pax <= listaPasajeros[direccion].length &&
                                        <>
                                            <div className="modal-body modal-body-passenger-modal">
                                                Todos los pasajeros ya fueron cargados.
                                            </div>
                                            <div className="modal-footer modal-footer-passenger-modal">
                                                <div className="row w-100 mb-2 text-center">
                                                    <div className="col-12 text-center">

                                                        <button type='button' className="btn_1 btn_1-passenger-modal mt-2" onClick={handleCloseModal}>
                                                            Aceptar
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    }
                                </form>
                            </div>
                        </div>
                    </div>
                </>
            }
        </>
    )
}

export default PassengerModal